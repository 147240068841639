import React from "react"

import Row from "./themableUI/row"
import Col from "./themableUI/col"

import LinkButton from "./link-button"

const Hero = () => (
  <div id="hero">
    <Row>
      <Col size={12}>
        <h2 className="bannerText heroText">
          Beautiful design and clean code for the web. <br />
          Made in the North.
        </h2>

        <LinkButton to={"/#about"}>Find out more</LinkButton>
      </Col>
    </Row>
  </div>
)

export default Hero
