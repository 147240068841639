import React from "react"
import styled, { css } from "styled-components"
import { getObjectStyles, merge } from "./utilities/themify"
const inputStyles = css`
  ${props => {
    const mergedThemes = props.theme.input
      ? merge(
          StyledInput.defaultProps.theme.input.styles,
          props.theme.input.styles
        )
      : StyledInput.defaultProps.theme.input.styles

    return getObjectStyles(mergedThemes)
  }}

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

const StyledInput = styled.input`
  ${inputStyles}
`

const StyledTextArea = styled.textarea`
  ${inputStyles}
  ${props => {
    const mergedThemes =
      props.theme.input && props.theme.input.textarea
        ? merge(
            StyledInput.defaultProps.theme.input.textarea.styles,
            props.theme.input.textarea.styles
          )
        : StyledInput.defaultProps.theme.input.textarea.styles
    return getObjectStyles(mergedThemes)
  }}
`

const StyledLabel = styled.label`
  ${props => {
    const mergedThemes =
      props.theme.input && props.theme.input.label
        ? merge(
            StyledInput.defaultProps.theme.input.label.styles,
            props.theme.input.label.styles
          )
        : StyledInput.defaultProps.theme.input.label.styles

    return getObjectStyles(mergedThemes)
  }}
`

export default function Input({ children, name, id, type, placeholder }) {
  return (
    <>
      <StyledLabel htmlFor={name}>{children}</StyledLabel>

      <StyledInput
        name={name}
        id={id}
        type={type}
        placeholder={placeholder}
      ></StyledInput>
    </>
  )
}

export function TextArea({ children, name, id, placeholder }) {
  return (
    <>
      <StyledLabel htmlFor={name}>{children}</StyledLabel>

      <StyledTextArea
        name={name}
        id={id}
        placeholder={placeholder}
      ></StyledTextArea>
    </>
  )
}

StyledInput.defaultProps = {
  theme: {
    input: {
      styles: {
        display: "block",
        width: "100%",

        padding: "0.375rem 0.75rem",
        // inset: "0.375rem 0.75rem",
        //

        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.5",
        color: "#212529",
        backgroundColor: "#fff",
        backgroundClip: "padding-box",
        border: "1px solid #ced4da",

        appearance: "none",
        borderRadius: "0.25rem",
        transition:
          "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",

        "&:focus": {
          color: "#212529",
          backgroundColor: "#fff",
          borderColor: "#86b7fe",
          outline: "0",
          boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
        },

        "::placeholder": {
          color: "#6c757d",
          opacity: "1",
        },
      },
      label: {
        styles: {
          marginBottom: "0.5rem",
        },
      },
      textarea: {
        styles: {
          minHeight: "calc(1.5em + 0.75rem + 2px)",
        },
      },
    },
  },
}
