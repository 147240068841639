import React from "react"
import styled, { css } from "styled-components"
import { getObjectStyles, merge } from "./utilities/themify"

const buttonStyles = css`
  ${props => {
    const mergedThemes = props.theme.button
      ? merge(
          StyledButton.defaultProps.theme.button.styles,
          props.theme.button.styles
        )
      : StyledButton.defaultProps.theme.button.styles
    return getObjectStyles(mergedThemes)
  }}

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

const StyledButton = styled.button`
  ${buttonStyles}
`

const StyledButtonLink = styled.a`
  ${buttonStyles}
`

export default function Button({ children, onClick, style }) {
  return (
    <StyledButton style={style} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export function ButtonLink({ children, href, style }) {
  return (
    <StyledButtonLink style={style} href={href}>
      {children}
    </StyledButtonLink>
  )
}

StyledButton.defaultProps = {
  theme: {
    button: {
      styles: {
        display: "inline-block",
        fontWeight: "400",
        lineHeight: "1.5",
        color: "#212529",
        textAlign: "center",
        textDecoration: "none",
        verticalAlign: "middle",
        cursor: "pointer",
        userSelect: "none",
        backgroundColor: "transparent",
        border: "1px solid transparent",

        padding: "0.375rem 0.75rem",
        // inset: "0.375rem 0.75rem",
        //

        fontSize: "1rem",
        borderRadius: "0.25rem",
        transition:
          "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",

        "&:hover": { color: "#212529" },
        "&:focus": {
          outline: "0",
          boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
        },

        "&:disabled, &.disabled": {
          pointerEvents: "none",

          opacity: "0.65",
        },
      },
    },
  },
}
