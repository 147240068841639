import React, { useContext, useRef } from "react"

import Seo from "../components/seo"

import Hero from "../components/hero"
import About from "../components/about"
import Contact from "../components/contact"
import Scope from "../components/scope/scope"

import { ThemeContext } from "styled-components"

const IndexPage = () => {
  const aboutRef = useRef()
  const contactRef = useRef()
  const themeContext = useContext(ThemeContext)

  return (
    <>
      <Scope
        aboutRef={aboutRef}
        contactRef={contactRef}
        color={themeContext.color1}
        color2={themeContext.color2}
        eraseColor={themeContext.backgroundColor}
      />
      <Seo title="Home" />

      <Hero></Hero>
      <div id="about" ref={aboutRef}>
        <About></About>
      </div>
      <div id="contact" ref={contactRef}>
        <Contact></Contact>
      </div>
    </>
  )
}

export default IndexPage
