import React from "react"

import Row from "./themableUI/row"
import Col from "./themableUI/col"

import ContactForm from "./contact-form"

const Contact = () => (
  <Row>
    <h2 className="screen-reader-text">Contact us</h2>

    <Col size={12} sizes={{ sm: 6 }}>
      <h2 className="bannerText contactText">We'd love to hear from you...</h2>
    </Col>
    <Col style={{ padding: "0" }} size={12} sizes={{ sm: 6 }}>
      <ContactForm></ContactForm>
    </Col>
  </Row>
)

export default Contact
