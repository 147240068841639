import React from "react"

import { Link } from "gatsby"

import Row from "./themableUI/row"
import Col from "./themableUI/col"

import LinkButton from "./link-button"

const About = () => (
  <Row>
    <Col size={12}>
      <h2 className="screen-reader-text">about</h2>
      <p className="aboutText">
        We are a newly formed design and development team, who create amazing
        user experiences for the web and mobile. Feel free to check out some
        examples of our work <Link to={"/work"}>here.</Link>
      </p>
      <p className="aboutText">
        If you would like to work with us, then please get in touch...
      </p>
      <LinkButton to={"/#contact"}>Contact Us</LinkButton>
    </Col>
  </Row>
)

export default About
